<template>
    <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">ALARMS</div>
            </div>
        </div>
        <hr>
        <b-table 
            :data='alarms'
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort=state
            defaultSortDirection='asc'
            >

            <template>
                <b-table-column field="product" label="Product" v-slot="props" sortable sorticon>
                    {{ props.row.type }}
                </b-table-column>

                <b-table-column field="category" label="Category" v-slot="props" sortable sorticon>
                    {{ props.row.category }}
                </b-table-column>

                <b-table-column field="sensorLocation" label="Tank" v-slot="props" sortable sorticon>
                    {{ props.row.sensorLocation }}
                </b-table-column>

                <b-table-column field="startDate" label="Start Date" v-slot="props" sortable softicon>
                    {{ props.row.activeTime | formatDateTime }}
                </b-table-column>

                <b-table-column field="endDate" label="Ending Date" v-slot="props" sortable sorticon>
                    {{ props.row.clearTime  | formatDateTime}}
                </b-table-column>

                <b-table-column field="endDate" label="Status" v-slot="props" sortable sorticon>
                    <span v-if="props.row.state != 'CLEARED'" class="tag is-danger">
                        <span class="icon" alt="Alarm" > <i class="fas fa-bell"></i></span>     
                    </span> 
                    <span v-if="props.row.state == 'CLEARED'" class="tag is-success">
                        <span class="icon" alt="Clear" > <i class="fas fa-check"></i></span>     
                    </span> {{ props.row.state}}
                </b-table-column>

            </template>
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

        </b-table>

    </div>
</template>

<script>
import UserStore from "../../../store/UserStore"

import dataLoading from '../../../components/app/DataLoading'

import axios from 'axios'

export default {

    components: {
        'data-loading': dataLoading,
    },

    metaInfo: {
        title: 'Recent Alarms Report'
    },

    data() {
        return {
            alarms: [],
            user: [],
            isPaginated: true,
            perPage: 10,
            isLoading: true,
            isFullPage: false
        }
    },

    methods: {

        fetchStoreAlarm() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/tanks/alarms'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.alarms = response.data.data
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }            
        },

    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },        
    },

    mounted() {
        this.user = this.getUser()
        this.fetchStoreAlarm()
    }

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>